import React from "react";
import Header from "../components/header/header.js";
import ReadMore from "../components/readMore/readMore.js";
import AndroidWellify from "../assets/img/android-wellify.png";
import Plus from "../assets/img/svg/plus.svg";

import "../assets/styles/pages/_wellness.scss";

const PageProps = {
  title: "Wellness",
  metaDescription: "",
};

const Wellness = () => {
  return (
    <>
      <Header {...PageProps} />
      <div className="container wellness">
        <div className="columns">
          <div className="column">
            <p>
              <span className="black bold">
                La nostra Suite Wellness è un potente strumento per gestire ed
                accrescere la tua brand reputation.
              </span>
              <div className="section" />
              Wellify è un software gestionale in cloud dedicato al settore
              wellness, che si rivolge in particolare a centri estetici, saloni
              di parrucchieri, SPA e centri benessere. Con Wellify avrai la
              possibilità di migliorare la gestione del tuo salone!
              <br />
              <br />
              Ti consentirà di gestire ordinatamente appuntamenti, anagrafiche,
              giacenze prodotti e ricevere prenotazioni online con aggiornamento
              automatico del tuo planning! Tutto ciò ti permetterà di creare un
              rapporto privilegiato con il tuo cliente facendolo sentire
              coccolato, ricordando i suoi appuntamenti e inviandogli promozioni
              dedicate attraverso le notifiche push dell’applicazione. Potrai
              inviare auguri personalizzati per compleanni e ricorrenze
              speciali.
              <br />
              <br />
              Inoltre, sarà indispensabile per la gestione del tuo magazzino;
              eviterai il rischio di avere tra le giacenze prodotti scaduti o
              inutilizzati, ottimizzando così gli investimenti e aumentando i
              profitti. Risparmia tempo ed investilo nella tua attività. Lascia
              gestire a noi la tua agenda! Potrai consultare Wellify da
              qualsiasi terminale ed ovunque tu sia!
            </p>
            <br />
            <br />
            <ReadMore to="https://www.wellify.cloud/" text="Scopri di più" color="white"  externalLink={true}/>
          </div>
          <div className="column is-offset-1">
            <img src={AndroidWellify} alt="Smartphone Wellify" />
          </div>
        </div>
        <div className="section" />
        <div className="columns">
          <div className="column">
            <img
              src="https://static.acmeproduzioni.it/eucleia/logo.svg"
              alt="Logo Eucleia"
            />
          </div>
          <div className="column">
            <img src={Plus} alt="" />
          </div>
          <div className="column">
            <img
              src="https://static.acmeproduzioni.it/wellify/logo.svg"
              alt="Logo Wellify"
            />
          </div>
        </div>
        <div className="section" />
      </div>
    </>
  );
};

export default Wellness;
